import React from "react";
import "./App.css";

function App() {
  const sendEmail = () => {
    window.location.href = "mailto:dmytro@gorshen.in";
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="wave-text" onClick={sendEmail}>
          Dmytro Gorshenin
        </h1>
        <h2>
          <a
            className="location"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/place/Miami,%20FL/"
          >
            <span className="fas fa-map-pin"></span> Miami, Florida, USA
          </a>
        </h2>{" "}
        {/* Add location here */}
        <div className="social-icons">
          <a
            href="mailto:dmytro@gorshen.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-envelope"></i>
          </a>
          <a
            href="https://www.instagram.com/dmytro.gorshenin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/dima.gorshenin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/dimahorshenin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
